import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
export class Service {
  code: string;
  organisationCount: number = 0;

  constructor(code: string, organisationCount: number) {
    this.code = code;
    this.organisationCount = organisationCount;
  }

  get name(): string {
    return Service.getServiceName(this.code);
  }

  static getServiceName(code): string {
    const names: any = {
      CNC: 'Click & Collect',
      DELIVERY: 'Livraison',
      GIFT_VOUCHER: 'Chèque Vitrines de Mulhouse',
      MEET: 'Prise de rendez-vous'
    };

    return names[code] ? names[code] : code;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ServiceAdapter implements Adapter<Service> {

  adapt(item: any): Service {
    return new Service(item.id, item.organisationCount);
  }
}
